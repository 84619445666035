import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['row']

  filter(event) {
    const query = event.target.value.toLowerCase()

    this.rowTargets.forEach((row) => {
      const text = row.textContent.toLowerCase()
      row.style.display = text.includes(query) ? '' : 'none'
    })
  }
}
